/**
 * Scriptor Jekyll Theme 2.0.0
 * By Just Good Themes - https://justgoodthemes.com/
*/

/* ==========================================================================
   Table of Contents
   ========================================================================== */
/*
  0. Icons
  1. Reset
  2. General
  3. Structure
  4. Site Header
  5. Content
  6. Comments
  7. Posts Navigation
  8. Site Footer
  9. Animations
 10. Prism
*/

// Font.
$font: (
  primary: ("Lato","Helvetica Neue",Helvetica,sans-serif),
  secondary: ("Noto Serif",Georgia,serif)
);

// Palette.

$palette: (
  accent:    #2d72d9,
  primary:   #6b747a,
  secondary: #2c343c,
  meta:      #9ba6ad,
  border:    #d7d7d7,
  bg:        #fbfbfb
);

// Imports
@import "functions";
@import "icons";
@import "reset";
@import "general";
@import "structure";
@import "site-header";
@import "content";
@import "comments";
@import "posts-navigation";
@import "site-footer";
@import "animations";
@import "prism";